import { jwtDecode } from "jwt-decode";

export default defineNuxtRouteMiddleware((to, from) => {
    const authStore = useAuthStore();

    const { authenticated } = storeToRefs(authStore);
    const token = useCookie('PUQ_TOKEN');

    if (token.value) {
        try {
            const userInfo = jwtDecode(token.value);
            if (!!Object.keys(userInfo).length) {
                authenticated.value = true;
            }
        } catch (error) {
            authenticated.value = false;
        }
    } else {
        authStore.refreshToken();
    }
});